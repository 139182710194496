import "./RedBar.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faInstagram, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons"
import ueFlag from "../../assets/Flag_of_Europe.svg.webp";
import roFlag from "../../assets/Flag_of_Romania_(2023–present).svg.png";

const RedBar = () => {
    return(
    <div className="redbar">
        <div className="flags">
        <img src={ueFlag} alt="Uniunea Europeana, European Union" className="ueFlag" />
        <img src={roFlag} alt="Romania" className="roFlag" />
        </div>
        <div className="socialMediaIcons">
         <FontAwesomeIcon icon={faFacebookF} onClick={() => window.open('https://www.facebook.com/TraditionalTV/')}/>
         <FontAwesomeIcon icon={faInstagram} onClick={() => window.open('https://www.instagram.com/traditional.tv/')} />
         <FontAwesomeIcon icon={faTiktok} onClick={() => window.open('https://www.tiktok.com/@traditionaltvromania?_t=8caC0iopKqg&_r=1')} />
         <FontAwesomeIcon icon={faYoutube} onClick={() => window.open('https://www.youtube.com/@TraditionalTV')} />
        </div>
    </div>
    )
}

export default RedBar;